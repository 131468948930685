import React, { useEffect, useState } from 'react';
import { GiTrophyCup } from 'react-icons/gi';
import Loader from '../Loader';
import Modal from '../Modal';
import axios from 'axios';

const QuizOver = React.forwardRef((props, ref) => {
	const {
		levelNames,
		score,
		maxQuestions,
		quizLevel,
		percent,
		loadLevelQuestions,
	} = props;

	const API_PUBLIC_KEY = process.env.REACT_APP_MARVEL_API_KEY;
	const hash = '74ba8bbfd9819719e87a8e700e5a0d52';

	const [asked, setAsked] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [characterInfos, setCharacterInfos] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setAsked(ref.current);
		if (localStorage.getItem('marvelStorageDate')) {
			const date = localStorage.getItem('marvelStorageDate');
			checkDataAge(date);
		}
	}, [ref]);

	const checkDataAge = date => {
		const today = Date.now();
		const timeDifference = today - date;

		const daysDifference = timeDifference / (1000 * 3600 * 24);

		if (daysDifference >= 15) {
			localStorage.clear();
			localStorage.setItem('marvelStorageDate', Date.now());
		}
	};

	const showModal = id => {
		setOpenModal(true);

		if (localStorage.getItem(id)) {
			setCharacterInfos(JSON.parse(localStorage.getItem(id)));
			setLoading(false);
		} else {
			axios
				.get(
					`https://gateway.marvel.com/v1/public/characters/${id}?ts1&apikey=${API_PUBLIC_KEY}&hash=${hash}`
				)
				.then(response => {
					console.log(response);
					setCharacterInfos(response.data);
					setLoading(false);
					localStorage.setItem(id, JSON.stringify(response.data));
					if (!localStorage.getItem('marvelStorageDate')) {
						localStorage.setItem('marvelStorageDate', Date.now());
					}
				})
				.catch(err => console.log(err));
		}
	};

	const hideModal = () => {
		setOpenModal(false);
		setLoading(true);
	};

	const capitalizeFirstLetter = string => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const averageGrade = maxQuestions / 2;

	if (score < averageGrade) {
		setTimeout(() => {
			loadLevelQuestions(quizLevel);
		}, 3000);
	}

	const decision =
		score >= averageGrade ? (
			<>
				<div className="stepsBtnContainer">
					{quizLevel < levelNames.length ? (
						<>
							<p className="successMsg">Bravo, passez au niveau suivant</p>
							<button
								onClick={() => loadLevelQuestions(quizLevel)}
								className="btnResult success">
								Niveau suivant
							</button>
						</>
					) : (
						<>
							<p className="successMsg">
								<GiTrophyCup size="50px" />
								Bravo, vous êtes un expert !
							</p>
							<button
								onClick={() => loadLevelQuestions(0)}
								className="btnResult gameOver">
								Recommencer le quiz
							</button>
						</>
					)}
				</div>
				<div className="percentage">
					<div className="progressPercent">Réussite : {percent}%</div>
					<div className="progressPercent">
						Note : {score}/{maxQuestions}
					</div>
				</div>
			</>
		) : (
			<>
				<div className="stepsBtnContainer">
					<p className="failureMsg">Dommage ! Vous avez échoué...</p>
				</div>
				<div className="percentage">
					<div className="progressPercent">Réussite : {percent}%</div>
					<div className="progressPercent">
						Note : {score}/{maxQuestions}
					</div>
				</div>
			</>
		);

	const questionAnswer =
		score >= averageGrade ? (
			asked.map(question => {
				return (
					<tr key={question.id}>
						<td>{question.question}</td>
						<td>{question.answer} </td>
						<td>
							<button
								onClick={() => showModal(question.heroId)}
								className="btnInfo">
								Infos
							</button>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="3">
					<Loader
						loadingMsg={'Pas de Réponses'}
						styling={{ textAlign: 'center', color: 'red' }}
					/>
				</td>
			</tr>
		);

	const resultInModal = !loading ? (
		<>
			<div className="modalHeader">
				<h2>{characterInfos.data.results[0].name}</h2>
			</div>
			<div className="modalBody">
				<div className="comicImage">
					<img
						style={{
							maxWidth: '350px',
						}}
						src={
							characterInfos.data.results[0].thumbnail.path +
							'.' +
							characterInfos.data.results[0].thumbnail.extension
						}
						alt={characterInfos.data.results[0].name}
					/>
					<p>{characterInfos.attributionText}</p>
				</div>
				<div className="comicDetails">
					<h3>Description</h3>
					{characterInfos.data.results[0].description ? (
						<p>{characterInfos.data.results[0].description}</p>
					) : (
						<p>Description non disponible</p>
					)}
					<h3>Plus d'infos</h3>
					{characterInfos.data.results[0].urls &&
						characterInfos.data.results[0].urls.map((url, index) => {
							return (
								<a
									href={url.url}
									target="_blank"
									rel="noopener noreferrer"
									key={index}>
									{capitalizeFirstLetter(url.type)}
								</a>
							);
						})}
				</div>
			</div>
			<div className="modalFooter">
				<button className="modalBtn" onClick={hideModal}>
					Fermer
				</button>
			</div>
		</>
	) : (
		<>
			<div className="modalHeader">
				<h2>Réponse de Marvel...</h2>
			</div>
			<div className="modalBody">
				<Loader />
			</div>
			<div className="modalFooter">
				<button className="modalBtn">Fermer</button>
			</div>
		</>
	);

	return (
		<>
			{decision}
			<hr />
			<p>Réponses aux questions :</p>
			<div className="answerContainer">
				<table className="answers">
					<thead>
						<tr>
							<th>questions</th>
							<th>Réponses</th>
							<th>Info</th>
						</tr>
					</thead>
					<tbody>{questionAnswer}</tbody>
				</table>
			</div>
			<Modal showModal={openModal} hideModal={hideModal}>
				{resultInModal}
			</Modal>
		</>
	);
});

export default React.memo(QuizOver);
