import React from 'react';

const Footer = () => {
	return (
		<footer className="">
			<div className="footer-container">
				<p>
					2020 - Projet réalisé par Younes HAIDRI avec les vidéos de DonkeyGeek
				</p>
				<p>
					Les icônes Wolverine, iron-man, Spider-man, Batman sont prises sur
					iconFinder.com
				</p>
			</div>
		</footer>
	);
};

export default Footer;
