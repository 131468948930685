import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../../App.css';
import ErrorPage from '../ErrorPage';
import Footer from '../Footer/Footer';
import ForgetPassword from '../ForgetPassword';
import Header from '../Header/Header';
import Landing from '../Landing';
import Login from '../login';
import Signup from '../Signup';
import Welcome from '../Welcome';
import { IconContext } from 'react-icons';

function App() {
	return (
		<Router>
			<IconContext.Provider
				value={{
					style: {
						verticalAlign: 'middle',
						marginRight: '15px',
						marginLeft: '15px',
						marginTop: '5px',
						marginBottom: '5px',
					},
				}}>
				<Header />
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route path="/welcome" component={Welcome} />
					<Route path="/login" component={Login} />
					<Route path="/signup" component={Signup} />
					<Route path="/forgetpassword" component={ForgetPassword} />
					<Route component={ErrorPage} />
				</Switch>
				<Footer />
			</IconContext.Provider>
		</Router>
	);
}

export default App;
