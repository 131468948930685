import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Levels from '../Levels';
import ProgressBar from '../ProgressBar';
import { QuizMarvel } from '../QuizMarvel';
import QuizOver from '../QuizOver';
import { FaChevronRight } from 'react-icons/fa';

toast.configure();

const initialState = {
	quizLevel: 0,
	maxQuestions: 10,
	storedQuestions: [],
	question: null,
	options: [],
	idQuestion: 0,
	btnDisabled: true,
	userAnswer: null,
	score: 0,
	showWelcomeMsg: false,
	quizEnd: false,
	percent: null,
};

const levelNames = ['debutant', 'confirme', 'expert'];

class Quiz extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.storedDataRef = React.createRef();
	}

	loadQuestions = quizz => {
		const { maxQuestions } = this.state;
		const fetchedArrayQuiz = QuizMarvel[0].quizz[quizz];
		if (fetchedArrayQuiz.length >= maxQuestions) {
			this.storedDataRef.current = fetchedArrayQuiz;
			const newArray = fetchedArrayQuiz.map(
				({ answer, ...keepRest }) => keepRest
			);
			this.setState({ storedQuestions: newArray });
		}
	};

	showToastMsg = pseudo => {
		const { showWelcomeMsg } = this.state;
		if (!showWelcomeMsg) {
			this.setState({ showWelcomeMsg: true });

			toast.warn(`Bienvenue ${pseudo} et bonne chance !`, {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				bodyClassName: 'toastify-color-welcome',
			});
		}
	};

	componentDidMount() {
		const { quizLevel } = this.state;
		this.loadQuestions(levelNames[quizLevel]);
	}

	submitAnswer = selectedAnswer => {
		this.setState({
			userAnswer: selectedAnswer,
			btnDisabled: false,
		});
	};

	getPercentage = (maxQuestions, ourScore) => (ourScore / maxQuestions) * 100;

	gameOver = percent => {
		const { quizLevel } = this.state;
		if (percent >= 50) {
			this.setState({
				quizLevel: quizLevel + 1,
				percent,
			});
		} else {
			this.setState({ percent });
		}
	};

	nextQuestion = () => {
		const { maxQuestions, idQuestion, userAnswer } = this.state;

		if (idQuestion === maxQuestions - 1) {
			this.setState({ quizEnd: true });
		} else {
			this.setState(prevState => ({ idQuestion: prevState.idQuestion + 1 }));
		}

		const goodAnswer = this.storedDataRef.current[idQuestion].answer;

		if (userAnswer === goodAnswer) {
			this.setState(prevState => ({ score: prevState.score + 1 }));
			toast.success('Bravo + 1', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				bodyClassName: 'toastify-color',
			});
		} else {
			toast.error('Raté !!', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				bodyClassName: 'toastify-color',
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const {
			maxQuestions,
			storedQuestions,
			idQuestion,
			score,
			quizEnd,
		} = this.state;

		if (
			storedQuestions !== prevState.storedQuestions &&
			storedQuestions.length
		) {
			this.setState({
				question: storedQuestions[idQuestion].question,
				options: storedQuestions[idQuestion].options,
			});
		}

		if (idQuestion !== prevState.idQuestion && storedQuestions.length) {
			this.setState({
				question: storedQuestions[idQuestion].question,
				options: storedQuestions[idQuestion].options,
				userAnswer: null,
				btnDisabled: true,
			});
		}

		if (quizEnd !== prevState.quizEnd) {
			const gradepercent = this.getPercentage(maxQuestions, score);
			this.gameOver(gradepercent);
		}

		if (this.props.userData.pseudo !== prevProps.userData.pseudo) {
			this.showToastMsg(this.props.userData.pseudo);
		}
	}

	loadLevelQuestions = param => {
		this.setState({ ...initialState, quizLevel: param });
		this.loadQuestions(levelNames[param]);
	};

	render() {
		const {
			quizLevel,
			maxQuestions,
			question,
			options,
			idQuestion,
			btnDisabled,
			userAnswer,
			score,
			quizEnd,
			percent,
		} = this.state;

		const displayOptions = options.map((option, index) => {
			return (
				<p
					key={index}
					onClick={() => this.submitAnswer(option)}
					className={`answerOptions ${
						userAnswer === option ? 'selected' : null
					}`}>
					<FaChevronRight />
					{option}
				</p>
			);
		});

		return quizEnd ? (
			<QuizOver
				ref={this.storedDataRef}
				levelNames={levelNames}
				score={score}
				maxQuestions={maxQuestions}
				quizLevel={quizLevel}
				percent={percent}
				loadLevelQuestions={this.loadLevelQuestions}
			/>
		) : (
			<>
				<Levels levelNames={levelNames} quizLevel={quizLevel} />

				<ProgressBar idQuestion={idQuestion} maxQuestions={maxQuestions} />
				<h2> {question}</h2>
				{displayOptions}
				<button
					disabled={btnDisabled}
					onClick={this.nextQuestion}
					className="btnSubmit">
					{idQuestion < maxQuestions - 1 ? 'Suivant' : 'Terminer'}
				</button>
			</>
		);
	}
}

export default Quiz;
